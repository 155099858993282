<template>
  <b-overlay
      :show="order.length === 0"
      rounded="sm"
  >
    <div v-if="order !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h2>اطلاعات کلی</h2>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="order"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: Status -->
          <template #cell(isPayed)="data">
            <b-badge
                v-if="data.item.isPayed"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              پرداخت شده
            </b-badge>
            <b-badge
                v-else
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              پرداخت نشده
            </b-badge>
          </template>

          <!-- Column: sumPrice -->
<!--          <template #cell(userInfo.userId)="data">-->
<!--            <span>-->
<!--               {{ data.item.userInfo.name+' '+data.item.userInfo.familyName }}-->
<!--            </span>-->
<!--          </template>-->

          <!-- Column: sumPrice -->
          <template #cell(sumPrice)="data">
            <span>
              {{ makePrettyPrice(data.item.sumPrice) }} تومان
            </span>
          </template>

          <!-- Column: createDate -->
          <template #cell(createDate)="data">
            <span>
              {{data.item.createDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createDate.slice(0,10))}}
            </span>
          </template>

          <!-- Column: isDelivered -->
          <template #cell(isDelivered)="data">
            <span v-if="data.item.isDelivered">تحویل داده شده</span>
            <span v-else>تحویل داده نشده</span>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <span v-if="data.item.status === 1">ثبت شده</span>
            <span v-else-if="data.item.status === 2">پرداخت شده</span>
            <span v-else-if="data.item.status === 3">ارسال شده</span>
            <span v-else-if="data.item.status === 4">تحویل داده شده</span>
            <span v-else-if="data.item.status === 5" class="text-danger">رد شده</span>
          </template>


        </b-table>
      </b-card>

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mt-3"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h2>جزییات</h2>
            </b-col>
          </b-row>

        </div>

          <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="order[0].subOrders"
              responsive
              :fields="myTableColumnsSubOrders"
              primary-key="id"
              show-empty
              bordered
              striped
              empty-text="موردی موجود نیست!"
          >
            <template #cell(productId)="data">
              <span v-if="data.item.productId">
                 {{ data.item.productInfo.productId }}
              </span>
              <span v-if="data.item.academyId">
                 {{ data.item.academyInfo.academyId }}
              </span>
              <span v-if="data.item.serviceId">
                 {{ data.item.serviceInfo.serviceId }}
              </span>
            </template>

            <!-- Column: productInfo -->
            <template #cell(productInfo)="data">
              <span v-if="data.item.productId">
                 {{ data.item.productInfo.title }}
              </span>
              <span v-if="data.item.academyId">
                 {{ data.item.academyInfo.title }}
              </span>
              <span v-if="data.item.serviceId">
                 {{ data.item.serviceInfo.title }}
              </span>
            </template>

            <!-- Column: amount -->
            <template #cell(amount)="data">
              <span>
                 {{ makePrettyPrice(data.item.amount) }} تومان
              </span>
            </template>

          </b-table>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {OrderGetRequest} from "@/libs/Api/Order";
import Helper from "@/libs/Helper";
export default {
  title:"اطلاعات سفارش",
  name: "OrderInfo",
  data(){
    return{
      order:[],
      orderId:this.$route.params.id,
      myTableColumns : [
        { key: 'orderId' ,label :'شناسه سفارش'},
        { key: 'userInfo.userId' ,label :'اطلاعات کاربر'},
        { key: 'isPayed' ,label :'وضعیت پرداخت'},
        // { key: 'description' ,label :'توضیحات'},
        { key: 'sumPrice' ,label :'مجموع'},
        { key: 'isDelivered',label: 'ترابری'},
        { key: 'createDate',label: 'تاریخ ثبت'},
        { key: 'status',label: 'وضعیت'},
      ],
      myTableColumnsSubOrders:[
        { key: 'productId' ,label :'شناسه محصول'},
        { key: 'productInfo' ,label :'نام محصول'},
        { key: 'count' ,label :'تعداد'},
        // { key: 'amount' ,label :'قیمت'},
      ],
    }
  },
  async created(){
    await this.getOrder();
  },
  methods:{
    async getOrder(){
      let _this = this;

      let orderGetRequest = new OrderGetRequest(_this);
      orderGetRequest.setId(_this.orderId);
      await orderGetRequest.fetch(function (content){
        _this.order.push(content)
      },function (error){
        console.log(error)
      })
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style scoped>

</style>
