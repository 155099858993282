import WebServiceRequest from '../Api/WebServiceRequest'

class OrderChangeStatusRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setId(data) {
        super.setTag('order/ChangeOrderStatus?orderId=' + data.orderId + '&status=' + data.status)
    }
}

class OrderCreateByAdmin extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('order/CreateByAdmin')
    }

    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

class OrderCreateRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('order/Create')
    }

    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

class OrderCreateForAdminRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('order/CreateForAdmin')
    }

    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

class OrderDeleteRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setId(id) {
        super.setTag('order/Delete?id=' + id)
    }
}

class OrderGetAllRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('order/GetAll')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class GetOrders extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('order/GetOrders')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class OrderGetByUserIdRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('order/GetByUserId')
    }
}

class OrderGetProductsForStudents extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag('order/GetProductsForStudents?searchCommand=' + data.searchCommand + '&pageNumber=' + data.pageNumber + '&count=' + data.count)
    }
}

class OrderGetRegisteredUserRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('order/GetRegisteredUser')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class OrderGetRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setId(id) {
        super.setTag('order/Get?orderId=' + id)
    }
}

export {
    OrderChangeStatusRequest,
    GetOrders,
    OrderCreateRequest,
    OrderCreateByAdmin,
    OrderDeleteRequest,
    OrderGetAllRequest,
    OrderGetByUserIdRequest,
    OrderGetProductsForStudents,
    OrderGetRegisteredUserRequest,
    OrderGetRequest,
    OrderCreateForAdminRequest
};
